import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../../components/shared/Loading";
import UserRow from "./UserRow";

const AllUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/user")
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
      });
  }, []);

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="table w-full">
          {/* <!-- head --> */}
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Email</th>
              <th>Make Admin</th>
              <th>Make Agent</th>
              <th>Remove User</th>
              {/* <th>Details</th> */}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <UserRow user={user} key={user._id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllUsers;
