import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

const SingleAgentApplication = () => {
  const [agentApp, setAgnetApp] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetch(
      `http://localhost:8000/agentapllication/${id}?timestamp=${Date.now()}`
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((json) => setAgnetApp(json))
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);
  return (
    <div className="mockup-window border">
      <div className="px-4 py-5 bg-base-200">
        <div>
          <Link to="/dashboard/agent-requests" className="btn btn-secondary">
            Back To Agent Request
          </Link>
        </div>
        <div>{agentApp._id}</div>
      </div>
    </div>
  );
};

export default SingleAgentApplication;
