import React from "react";
import { useEffect } from "react";
import AllApplication from "./AllApplication";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";

const AllApplications = () => {
  const [applicatiions, setApplicatiions] = useState([]);
  const [user, loading] = useAuthState(auth);

  //load data by useEffect from manageapllications by query logedInUserEmail
  useEffect(() => {
    const getApplications = async () => {
      const url = `http://localhost:8000/manageapllication?body.email=${user.email}`;
      try {
        await fetch(url)
          .then((res) => res.json())
          .then((data) => setApplicatiions(data));
      } catch (error) {
        console.log(error?.message);
      }
    };
    getApplications();
  }, [user.email]);

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="table w-full">
          {/* <!-- head --> */}
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Student Name</th>
              <th>Student Email</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Session</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {applicatiions.map((application) => (
              <AllApplication application={application} key={application._id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllApplications;
