import { useEffect, useState } from "react";

const useAgent = (user) => {
  const [agent, setAgent] = useState(false);
  const [agentLoading, setAgentLoading] = useState(true);
  useEffect(() => {
    const email = user?.email;
    if (email) {
      fetch(`http://localhost:8000/agent/${email}`)
        .then((res) => res.json())
        .then((data) => {
          setAgent(data.agent);
          setAgentLoading(false);
        });
    }
  }, [user]);

  return [agent, agentLoading];
};

export default useAgent;
